import React from "react";
import Home from "./../../containers/app/home/index";
import Settings from "../../containers/app/setting";
import VideoInformation from "../../containers/app/videoInformation";
import LoaderPage from "../../containers/app/loader";
import Congratulation from "../../containers/app/congratulation";
import Winner from "../../containers/app/winner";
import Certificate from "../../containers/app/certificate";
import Profile from "../../containers/app/profile";
import PackagesPlan from "../../containers/app/packages";
import PrivacyAndTerms from "../../containers/app/privacyAndTerms";
import HelpCenter from "../../containers/app/helpCenter";
import AboutUs from "../../containers/app/about";

const PagesRoutes = [
  {
    id: 1,
    name: "Home",
    component: <Home />,
    exact: "exact",
    path: "",
    icon: "home",
  },
  {
    id: 2,
    name: "Video Info",
    component: <VideoInformation />,
    exact: "exact",
    path: "videoInfo",
    icon: "video_library",
  },
  {
    id: 3,
    name: "Loader",
    component: <LoaderPage />,
    exact: "exact",
    path: "loader",
    icon: "autorenew",
  },
  {
    id: 4,
    name: "Setting",
    component: <Settings />,
    exact: "exact",
    path: "setting",
    icon: "settings",
  },
  {
    id: 5,
    name: "Congratultion",
    component: <Congratulation />,
    exact: "exact",
    path: "congratulation",
    icon: "wifi_tethering",
  },
  {
    id: 6,
    name: "Winner",
    component: <Winner />,
    exact: "exact",
    path: "winner",
    icon: "emoji_events",
  },
  {
    id: 7,
    name: "Certificate",
    component: <Certificate />,
    exact: "exact",
    path: "certificate",
    icon: "shield",
  },
];

const AdminRoutes = [
  {
    id: 1,
    name: "Packages",
    component: <PackagesPlan />,
    exact: "exact",
    path: "packages",
    icon: "monetization_on",
  },
  {
    id: 2,
    name: "Profile",
    component: <Profile />,
    exact: "exact",
    path: "profile",
    icon: "account_circle",
  },
  {
    id: 3,
    name: "Privacy and Terms",
    component: <PrivacyAndTerms />,
    exact: "exact",
    path: "privacyAndTerms",
    icon: "privacy_tip",
  },
  {
    id: 4,
    name: "About Us",
    component: <AboutUs />,
    exact: "exact",
    path: "about-us",
    icon: "privacy_tip",
  },
  {
    id: 5,
    name: "Help Center",
    component: <HelpCenter />,
    exact: "exact",
    path: "helpCenter",
    icon: "live_help",
  },
];

export { PagesRoutes, AdminRoutes };
