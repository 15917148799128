import React, { useEffect, useState } from "react";
import DrawerComponent from "../../../components/NavigationDrawer";
import Loader from "../../../components/Loader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import { Box, Typography } from "@mui/material";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  fetchPrivacyAndTerms,
  updatePrivacyAndTerms,
} from "./../../../services/privacyAndTerms";
import CustomButton from "../../../components/CustomButton";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [updateId, setUpdateId] = useState("");

  const [aboutUs, setAboutUs] = useState(() => EditorState.createEmpty());
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const onAboutUsStateChange = (editorState) => {
    setAboutUs(editorState);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    setIsLoading(true);
    fetchPrivacyAndTerms()
      .then((res) => {
        console.log(res, "Sfasfasfasff");
        if (res?.status == 200 || res?.status == 201) {
          setIsLoading(false);
          let data = res?.data?.data;
          setUpdateId(data);
          //setPrivacy state
          const aboutUsContentState = ContentState.createFromBlockArray(
            convertFromHTML(data.about)
          );
          setAboutUs(EditorState.createWithContent(aboutUsContentState));
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        handleSnackbarVal(true, "error", err?.message);
        setIsLoading(false);
      });
  };

  const handleUpdate = () => {
    const aboutUsContent = draftToHtml(
      convertToRaw(aboutUs.getCurrentContent())
    );

    const payload = {
      about: aboutUsContent,
    };

    setIsLoading(true);
    updatePrivacyAndTerms(updateId._id, payload)
      .then((resss) => {
        setIsLoading(false);
        console.log(resss, "ddasdasdsdsd");
        if (resss?.data?.status === "ok") {
          fetchSettings();
          handleSnackbarVal(true, "success", resss?.data?.message);
        } else {
          handleSnackbarVal(true, "error", resss?.data?.message);
        }
      })
      .catch((errrr) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", errrr?.message);
      });
  };

  return (
    <DrawerComponent>
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Typography variant={"h6"} fontSize={22} fontWeight={600}>
        About Us
      </Typography>
      <Box mt={2} sx={{ backgroundColor: "#fff" }} p={"20px 20px"}>
        <Editor
          editorState={aboutUs}
          onEditorStateChange={onAboutUsStateChange}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "history",
              "colorPicker",
              "link",
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </Box>
      <Box width={150} height={40} margin={"10px 0px 10px auto"}>
        <CustomButton btnLabel={"Update"} handlePressBtn={handleUpdate} />
      </Box>
    </DrawerComponent>
  );
}

export default AboutUs;
